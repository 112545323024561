import { isNullOrUndefined } from "@clipboard-health/util-ts";
import {
  type HelloSignSessionResponse,
  helloSignSessionResponseSchema,
} from "@src/appV2/Accounts/Documents/resources/hellosign/types";
import { post } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { HELLO_SIGN_SESSION_RESOURCE } from "./constants";

const createHelloSignSessionSchema = z.object({
  data: z.object({
    type: z.literal(HELLO_SIGN_SESSION_RESOURCE),
    attributes: z
      .object({
        childRequirementId: z.string(),
      })
      .optional(),
  }),
});

type CreateHelloSignSession = z.infer<typeof createHelloSignSessionSchema>;

interface UseCreateHelloSignSessionProps {
  requirementId?: string;
  workerId?: string;
}

export function useCreateHelloSignSession(
  props: UseCreateHelloSignSessionProps,
  options: UseMutationOptions<HelloSignSessionResponse, AxiosError, CreateHelloSignSession> = {}
): UseMutationResult<HelloSignSessionResponse, AxiosError, CreateHelloSignSession> {
  return useMutation({
    mutationFn: async (variables: CreateHelloSignSession) => {
      const { requirementId, workerId } = props;

      if (isNullOrUndefined(workerId) || isNullOrUndefined(requirementId)) {
        throw new Error("Missing required parameters");
      }

      const response = await post({
        url: `${environmentConfig.REACT_APP_DOCUMENTS_SERVICE_REST_API_URL}/workers/${workerId}/requirements/${requirementId}/hello-sign-sessions`,
        data: variables,
        requestSchema: createHelloSignSessionSchema,
        responseSchema: helloSignSessionResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.CREATE_HELLO_SIGN_SESSION_FAILURE,
    },
    ...options,
  });
}
