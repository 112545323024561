import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";

import { DocumentRequirementType } from "../../Documents/types";
import { DocumentDetailsContent } from "../components/DocumentDetailsContent";
import { PersonalIdOptionPicker } from "../components/PersonalIdForm";
import { useDocumentUploadContext } from "../hooks";
import { useDocumentDetailsContext } from "../useDocumentDetails";

interface RequirementChecklist {
  description?: string;
  enabled: boolean;
  items: string[];
}

const defaultChecklistParams: RequirementChecklist = {
  description: undefined,
  enabled: false,
  items: [],
};

export function RequirementDetails() {
  const {
    isRequirementPersonalId,
    selectedRequirement,
    personalIdSubtypes,
    personalIdSubtype,
    setPersonalIdSubtype,
    requirementType,
    instructions,
    fallbackStaticUrl,
  } = useDocumentDetailsContext();

  const { selectedFiles } = useDocumentUploadContext();

  return (
    <Stack spacing={2}>
      {isDefined(selectedRequirement) && (
        <DocumentDetailsContent
          description={selectedRequirement.description}
          instructions={instructions}
          fallbackStaticUrl={fallbackStaticUrl}
          checklist={
            isDefined(selectedRequirement.checklist)
              ? {
                  description:
                    selectedRequirement.checklist.description ?? defaultChecklistParams.description,
                  enabled: selectedRequirement.checklist.enabled ?? defaultChecklistParams.enabled,
                  items: selectedRequirement.checklist.items ?? defaultChecklistParams.items,
                }
              : defaultChecklistParams
          }
        />
      )}
      {requirementType !== DocumentRequirementType.PENDING &&
        isRequirementPersonalId &&
        isDefined(personalIdSubtypes) &&
        selectedFiles.length === 0 && (
          <PersonalIdOptionPicker
            options={personalIdSubtypes.map((value: string) => ({
              value,
              label: value,
            }))}
            selectedValue={personalIdSubtype}
            onChange={(event) => {
              setPersonalIdSubtype(event.target.value);
            }}
          />
        )}
    </Stack>
  );
}
