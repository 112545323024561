import { isDefined } from "@clipboard-health/util-ts";
import { type Resources } from "@src/appV2/Accounts/Documents/resources/constants";
import {
  getHelloSignSessionQueryParamsSchema,
  type HelloSignSessionResponse,
  helloSignSessionResponseSchema,
} from "@src/appV2/Accounts/Documents/resources/hellosign/types";
import { useGetQuery, type UseGetQueryOptions } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import { type UseQueryResult } from "@tanstack/react-query";

interface UseGetHelloSignParams {
  requirementId: string;
  helloSignSessionId: string;
  workerId: string;
  include?: Resources.HCP_DOCUMENTS;
}

export function useGetHelloSignSession(
  props: UseGetHelloSignParams,
  options: UseGetQueryOptions<HelloSignSessionResponse> = {}
): UseQueryResult<HelloSignSessionResponse> {
  const { requirementId, helloSignSessionId, include, workerId } = props;
  return useGetQuery({
    url: `${environmentConfig.REACT_APP_DOCUMENTS_SERVICE_REST_API_URL}/workers/${workerId}/requirements/${requirementId}/hello-sign-sessions/${helloSignSessionId}`,
    queryParams: isDefined(include) ? { include } : undefined,
    requestSchema: getHelloSignSessionQueryParamsSchema,
    responseSchema: helloSignSessionResponseSchema,
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_HELLO_SIGN_SESSION_FAILURE,
    },
    ...options,
  });
}
