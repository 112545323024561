import { isDefined, isNullOrUndefined } from "@clipboard-health/util-ts";
import { type Resources } from "@src/appV2/Accounts/Documents/resources/constants";
import { HelloSignWorkflowSessionStatus } from "@src/appV2/Accounts/Documents/resources/hellosign/constants";
import {
  type HelloSignSessionResponse,
  helloSignSessionResponseSchema,
} from "@src/appV2/Accounts/Documents/resources/hellosign/types";
import { patch } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib";
import {
  useMutation,
  type UseMutationOptions,
  type UseMutationResult,
} from "@tanstack/react-query";
import { type AxiosError } from "axios";
import { z } from "zod";

import { HELLO_SIGN_SESSION_RESOURCE } from "./constants";

const updateHelloSignSessionAttributes = z
  .object({
    status: z.union([
      z.literal(HelloSignWorkflowSessionStatus.ERRORED),
      z.literal(HelloSignWorkflowSessionStatus.SIGNED),
    ]),
    errorMessage: z.string().optional(),
  })
  .refine(
    (data) =>
      data.status !== HelloSignWorkflowSessionStatus.SIGNED || isNullOrUndefined(data.errorMessage),
    {
      message: "errorMessage must not be present when status is SIGNED",
      path: ["errorMessage"],
    }
  );

type UpdateHelloSignSessionAttributes = z.infer<typeof updateHelloSignSessionAttributes>;

const updateHelloSignSessionSchema = z.object({
  data: z.object({
    attributes: updateHelloSignSessionAttributes,
    type: z.literal(HELLO_SIGN_SESSION_RESOURCE),
  }),
});

interface UseUpdateHelloSignSessionProps {
  helloSignSessionId?: string;
  requirementId?: string;
  workerId?: string;
  include?: Resources.HCP_DOCUMENTS;
}

export function useUpdateHelloSignSession(
  props: UseUpdateHelloSignSessionProps,
  options: UseMutationOptions<
    HelloSignSessionResponse,
    AxiosError,
    UpdateHelloSignSessionAttributes
  > = {}
): UseMutationResult<HelloSignSessionResponse, AxiosError, UpdateHelloSignSessionAttributes> {
  const { helloSignSessionId, requirementId, workerId, include } = props;

  return useMutation({
    mutationFn: async (attributes: UpdateHelloSignSessionAttributes) => {
      if (
        isNullOrUndefined(workerId) ||
        isNullOrUndefined(requirementId) ||
        isNullOrUndefined(helloSignSessionId)
      ) {
        throw new Error("Missing required parameters");
      }

      const response = await patch({
        url: `${environmentConfig.REACT_APP_DOCUMENTS_SERVICE_REST_API_URL}/workers/${workerId}/requirements/${requirementId}/hello-sign-sessions/${helloSignSessionId}`,
        queryParams: isDefined(include) ? { include } : undefined,
        data: {
          data: {
            attributes,
            type: HELLO_SIGN_SESSION_RESOURCE,
          },
        },
        requestSchema: updateHelloSignSessionSchema,
        responseSchema: helloSignSessionResponseSchema,
      });

      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.UPDATE_HELLO_SIGN_SESSION_FAILURE,
    },
    ...options,
  });
}
